/*globals $ */

class HeroImg {
  constructor(manager, el) {
    this.manager = manager;
    this.$el = el;
    this.src_original = this.$el.attr('data-original');
    console.log('this.src_original', this.src_original);
    this.isFadein = false;
  }
  load() {
    $('<img>')
      .one('load',() => {
        this.manager.loadCompleteNum++;
        this.$el.css('background-image', 'url("' + this.src_original + '")');

        this.$el.loaded = true;
        this.$el.addClass('loaded');
        this.manager.loadNext();
      })
      .attr('src', this.src_original);
  }
  fadein(depth) {
    if(!this.isFadein) {
      this.isFadein = true;
      this.$el
        .stop(false)
        .css({'display':'block', 'z-index':depth});
      setTimeout(() => {
        this.$el.addClass('-fadein');
      }, 50);
    }

  }
  fadeout() {
    if(this.isFadein) {
      this.isFadein = false;
      this.$el.removeClass('-fadein');
    }
  }
}

class HeroSlideshow01 {
  constructor() {
    // console.log('HeroSlideshow01');
    this.$container = $('.HeroSlideshow');
    this.images = [];
    this.depth = 0;
    this.currentIndex = 0;

    this.$container.find('.HeroSlideshowImg').each((i, e) => {
      this.images.push(new HeroImg(this, $(e)));
    });
    this.loadCompleteNum = 0;

    // console.log(this.images);
    this.images[0].load();

  }
  loadNext() {
    if(this.loadCompleteNum < this.images.length) {
      this.images[this.loadCompleteNum].load();
    }
  }
  start() {
    console.log('Slideshow start');
    this.depth++;
    this.images[this.currentIndex].fadein(this.depth);

    this.slideInterval = setInterval(() => {
      this.next();
    }, 6000);
  }
  next() {
    this.depth++;
    this.currentIndex++;
    if(this.currentIndex == this.images.length) this.currentIndex = 0;
    for(let i = 0; i < this.images.length; i++) {
      if(i == this.currentIndex) {
        this.images[this.currentIndex].fadein(this.depth);
      } else {
        this.images[i].fadeout();
      }
    }

  }
}
export default HeroSlideshow01;
