/*globals $ */

import InViewObj from './InViewObj';
import PerfectScrollbar from 'perfect-scrollbar';

class Modal {
  constructor(mainApp) {
    this.mainApp = mainApp;
    this.$container = $('.Modal');
    this.fadeinObjects = [];
    // this.$bottomLink = $('.BottomLink');
    this.isFadein = false;
    new PerfectScrollbar('#ModalScroller', {
      suppressScrollX: true
    });

    $('.BtnCloseModal').on('click', () => {
      this.close();
      return false;
    });

    this.$container.find('.InViewObj').each((i, e) => {

      this.fadeinObjects.push(new InViewObj($(e)));
    });
  }
  offTouchEvent(e) {
    e.preventDefault();
  }

  open() {
    if(!this.isFadein) {

      let timeoutTime = 0;
      if(this.mainApp.menu.isFadein) {
        this.mainApp.menu.close();
        timeoutTime = 100;
      }

      setTimeout(() => {
        document.addEventListener('touchmove', this.offTouchEvent, {passive: false});
        this.tmpScrollTop = this.mainApp.scrollTop;
        $('html').css('overflow-y', 'hidden');
        $('body').addClass('menu-open');
        this.isFadein = true;

        this.$container.stop(false).css({
          'display': 'block',
          'opacity': 0
        });
        this.$container.animate({'opacity':1}, 300, 'easeInOutSine');

        $('#ModalScroller')[0].scrollTop = 0;
      }, timeoutTime);



    }
  }
  close() {
    if(this.isFadein) {
      // this.$bottomLink.removeClass('BottomLink--fadein');
      document.removeEventListener('touchmove', this.offTouchEvent);
      $('html').css('overflow-y', 'visible');
      this.mainApp.scrollPage(this.tmpScrollTop, true);
      this.mainApp.onWindowResize();

      $('body').removeClass('menu-open');
      this.isFadein = false;
      this.$container.css({
        'display': 'none',
        'opacity': 0
      });

      /*
      clearInterval(this.fadeinInterval);

      for(let i = 0; i < this.fadeinObjects.length; i++) {
        this.fadeinObjects[i].fadeout();
      }
      */
    }
  }
}

export default Modal;
