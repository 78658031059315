/*globals $ */

import SvgImg from './SvgImg';

class NewsListPage {
  constructor($page) {
    this.$page = $page;
    this.items = [];
    this.$page.find('.NewsListItem').each((i, e) => {
      this.items.push($(e));
    });
    this.isFadein = false;
  }
  fadein() {
    if(!this.isFadein) {
      this.isFadein = true;
      this.$page.find('.NewsListItem').css('opacity', 0);
      this.$page.css('display', 'block');
      setTimeout(() => {
        for(let i = 0; i < this.items.length; i++) {
          setTimeout(() => {
            this.items[this.items.length - 1 - i].css('opacity', 1);
          }, i * 100);
        }
      }, 100);
    }
  }
  fadeout() {
    if(this.isFadein) {
      this.isFadein = false;
      this.$page.css('display', 'none');
      for(let i = 0; i < this.items.length; i++) {
        this.items[i].css('opacity', 1);
      }
    }
  }
}

class News {
  constructor() {
    this.$container = $('.News');
    this.titleSvg = new SvgImg(this.$container.find('.SvgImg'));
    this.pages = [];
    this.newsListItems = [];
    this.currentPage = 0;
    this.$dots = [];

    this.$container.find('.NewsListPage').each((i, e) => {
      this.pages.push(new NewsListPage($(e)));
    });

    if(this.pages.length > 1) {
      $('.NewsDots').find('.BtnNewsDots').each((i, e) => {
        this.$dots.push($(e));
      });
      $('.NewsDots').find('.BtnNewsDots').on('click', (e) => {
        this.changePage(parseInt($(e.currentTarget).attr('data-index')));
      });
    }

    /*
    this.$container.find('.NewsListItem').each((i, e) => {
      this.newsListItems.push($(e));
    });
    */
  }
  changePage(target) {
    this.currentPage = target;

    for(let i = 0; i < this.pages.length; i++) {
      if(target == i) {
        this.$dots[i].addClass('BtnNewsDots--curent');
        this.pages[i].fadein();
      } else {
        this.$dots[i].removeClass('BtnNewsDots--curent');
        this.pages[i].fadeout();
      }
    }
  }
  fadein() {
    this.titleSvg.fadein();

    if(this.pages.length > 1) {
      setTimeout(() => {
        $('.NewsDots').addClass('NewsDots--fadein');
      }, 400);

      setTimeout(() => {
        this.pages[0].fadein();
      }, 500);
    } else {
      setTimeout(() => {
        this.pages[0].fadein();
      }, 400);
    }
  }
}

export default News;
